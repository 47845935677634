<template>
<vx-card no-shadow title="Customer" :customTitle="this.title()">
    <template slot="actions">
        <vs-button color="dark" @click="goBack">Back</vs-button>
    </template>
    <vs-divider class="mt-1 mb-0" />
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Personal Details' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                <Profile :selectedEmployee="employee" @updateEmployeeId="updateEmployeeId"></Profile>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-map-pin" :label="!isSmallerScreen ? 'Home Address' : ''">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <HomeAddress :customerId="customerID" :customerAddress="customerAddress"></HomeAddress>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Mailing Address' : ''">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <MailAddress :customerId="customerID" :customerMailingAddress="customerMailingAddress" @sameAsAddress="sameAsAddress"></MailAddress>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? 'Driver\'s Licence' : ''">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <DrivingLicence :customerId="customerID" :customerDrivingLicence="customerDrivingLicence"></DrivingLicence>
            </div>
        </vs-tab>

    </vs-tabs>
</vx-card>
</template>

<script>
import {
    profileData
} from "../../store/api/profile"
import Profile from "./tabEmployerProfile.vue";
import HomeAddress from "./tabHomeAddress.vue";
import MailAddress from "./tabMailAddress.vue";
import DrivingLicence from "./tabDrivingLicence.vue";

export default {
    data() {
        return {
            customerId: "",
            employee: {},
            customerAddress: {},
            customerMailingAddress: {},
            customerDrivingLicence: {}
        };
    },
    components: {
        Profile,
        HomeAddress,
        MailAddress,
        DrivingLicence,
    },
    props: {
        selectedCompany: {
            type: Object,
        },
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768;
        },
        customerID() {
            return this.$route.params.id;
        }
    },
    async created() {
        this.getCustomerProfile();
    },
    methods: {
        sameAsAddress() {
            this.customerMailingAddress = this.customerAddress;
        },
        async getCustomerProfile() {
            this.employee = await profileData.searchProfileById(this.customerID)
            if (this.employee) {
                this.getCustomerAddress();
                this.getCustomerMailingAddress();
                this.getDrivingLicence();
            }
        },
        async getCustomerAddress() {
            this.customerAddress = await profileData.SearchContactAddressByUser(this.customerID);
            if (!this.customerAddress) {
                this.customerAddress = {};
            }
        },
        async getCustomerMailingAddress() {
            this.customerMailingAddress = await profileData.SearchContactMailingAddressByUser(this.customerID);
            if (!this.customerMailingAddress) {
                this.customerMailingAddress = {};
            }
        },
        async getDrivingLicence() {
            this.customerDrivingLicence = await profileData.SearchIdentityDocumentByUser(this.customerID)
            if (!this.customerDrivingLicence) {
                this.customerDrivingLicence = {};
            }
        },
        title() {
            if (this.employee && this.employee.firstname) {
                return `${this.employee.firstname} ${this.employee.lastname}`;
            } else {
                return "";
            }
        },
        updateEmployeeId(id) {
            this.currentEmployeId = id;
        },
        goBack() {
            this.$router.back();
        }
    }
};
</script>
