<template>
<ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitUser)" autocomplete="off">
        <h5 class="mt-4 text-secondary">Driver's Licence</h5>
        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Licence Number:</span>
            </div>

            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" name="number" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 15 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="number" v-model="drivingLicence.documentNumber" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">State:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-select name="state" v-model="drivingLicence.state" class="w-full">
                        <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item, index) in stateList" />
                    </vs-select>
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-base">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Expiry Date:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="ExpiryDate">
                    <flat-pickr class="w-full" name="ExpiryDate" :config="DateTimeConfig" v-model="drivingLicence.expiryDate" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right"></span>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
                <iframe class="ml-5 width80" style="height:40vh;" v-if="drivingLicence.fileExtension === '.pdf'" :key="drivingLicence.downloadUrl" :src="drivingLicence.downloadUrl" />
                <img class="ml-5 width80" :key="drivingLicence.downloadUrl" v-else-if="drivingLicence.downloadUrl && imageExt.includes(drivingLicence.fileExtension)" :src="drivingLicence.downloadUrl" />
            </div>
        </div>

        <h5 class="mt-4 text-secondary">Upload Document</h5>
        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right"></span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vue2Dropzone v-if="isUpdated" @vdropzone-success="fileUploaded" @vdropzone-error="fileUploadFailed" class="w-full border-dotted" ref="myVueDropzone" id="dropzone" @vdropzone-sending="sendingEvent" :options="dropzoneOptions" @vdropzone-files-added="fileAdded"></vue2Dropzone>
            </div>
        </div>
        <vs-divider />
        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right"></span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <vs-button @click="submitUser">Submit</vs-button>
            </div>
        </div>
    </form>
</ValidationObserver>
</template>

<script>
import {
    customMessages
} from "./../../filters/validationmessage";

import {
    treeMixData
} from "../../store/api/treeMix";

import GeoSuggest from "../../components/GeoSuggest/GeoSuggest";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import {
    BaseConfig
} from "../../store/api/config";

import AuthService from "../../services/AuthService";
const authservice = new AuthService();

export default {
    props: {
        customerDrivingLicence: {
            type: Object,
            default: () => {},
        },
        customerId: {
            type: String,
            default: () => ""
        },
    },
    watch: {
        customerId: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {}
        },
        customerDrivingLicence: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.drivingLicence = newValue;
            }
        }
    },
    data() {
        return {
            customMessages,
            drivingLicence: {},
            fileData: [],
            stateList: [],
            isUpdated: false,
            DateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
                minDate: new Date(),
            },
            fileUploadAction: "",
            dropzoneOptions: {
                url: `${BaseConfig.BHAPI}api/IdentityDocument/UploadDrivingLicenseDocumentsByUser`,
                maxFilesize: 2560,
                thumbnailWidth: 300,
                thumbnailHeight: 300,
                maxFiles: 1,
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    });
                },
                addRemoveLinks: true,
            },
            imageExt: [".jpg", ".jpeg", ".png"],
        };
    },
    components: {
        GeoSuggest,
        flatPickr,
        vue2Dropzone
    },
    async created() {
        let token = await authservice.getSilentToken("bh");
        let myHeaders = {
            Authorization: "Bearer " + token
        };
        this.headers = myHeaders;
        this.fileUploadAction = `${BaseConfig.BHAPI}api/IdentityDocument/UploadDrivingLicenseDocumentsByUser`;
        await this.loadFileConfig();
        this.stateList = treeMixData.searchListOfStates();
    },

    methods: {
        fileAdded(file) {
            this.fileData = [];
            this.fileData = file
        },
        async loadFileConfig() {
            this.dropzoneOptions = {
                ...this.dropzoneOptions,
                url: this.fileUploadAction,
                headers: this.headers,
                includeStyling: true,
                duplicateCheck: true,
                autodiscover: false,
                autoQueue: false,
                autoProcessQueue: false,
                acceptedFiles: ".png,.jpg,.pdf,.jpeg",
            }
            this.isUpdated = true
        },
        fileUploaded(file, response) {
            this.$vs.notify({
                title: "Success",
                text: "Document Uploaded",
                color: "success",
                position: "top-center"
            });
        },
        sendingEvent(file, xhr, formData) {
            formData.append("documentNumber", this.drivingLicence.documentNumber);
            formData.append("expiryDate", this.drivingLicence.expiryDate);
            formData.append("state", this.drivingLicence.state);
            formData.append("userId", this.customerId);
        },
        fileUploadFailed(file, message, xhr) {
            console.log("file uploading failed");
            // this.$vs.notify({
            //     title: "Failed",
            //     text: "Media Upload Failed",
            //     color: "danger",
            //     position: "top-center"
            // });
        },
        async submitUser() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            if (this.fileData.length > 0) {
                await this.$refs.myVueDropzone.dropzone.processFiles(this.fileData);
            } else {
                this.$vs.notify({
                    title: "Required",
                    text: "To update licence record, please upload a new file.",
                    color: "danger",
                    position: "top-center"
                });
            }

        },
    },
};
</script>

<style lang="scss" scoped>
.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>
