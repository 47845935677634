var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitUser)}}},[_c('h5',{staticClass:"mt-4 text-secondary"},[_vm._v("Driver's Licence")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("Licence Number:")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"number","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 15 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"number"},model:{value:(_vm.drivingLicence.documentNumber),callback:function ($$v) {_vm.$set(_vm.drivingLicence, "documentNumber", $$v)},expression:"drivingLicence.documentNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("State:")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"name":"state"},model:{value:(_vm.drivingLicence.state),callback:function ($$v) {_vm.$set(_vm.drivingLicence, "state", $$v)},expression:"drivingLicence.state"}},_vm._l((_vm.stateList),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.code,"text":item.name}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-base"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("Expiry Date:")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true },"custom-messages":_vm.customMessages,"name":"ExpiryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"w-full",attrs:{"name":"ExpiryDate","config":_vm.DateTimeConfig},model:{value:(_vm.drivingLicence.expiryDate),callback:function ($$v) {_vm.$set(_vm.drivingLicence, "expiryDate", $$v)},expression:"drivingLicence.expiryDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"})]),_c('div',{staticClass:"vx-col sm:w-1/2 w-full"},[(_vm.drivingLicence.fileExtension === '.pdf')?_c('iframe',{key:_vm.drivingLicence.downloadUrl,staticClass:"ml-5 width80",staticStyle:{"height":"40vh"},attrs:{"src":_vm.drivingLicence.downloadUrl}}):(_vm.drivingLicence.downloadUrl && _vm.imageExt.includes(_vm.drivingLicence.fileExtension))?_c('img',{key:_vm.drivingLicence.downloadUrl,staticClass:"ml-5 width80",attrs:{"src":_vm.drivingLicence.downloadUrl}}):_vm._e()])]),_c('h5',{staticClass:"mt-4 text-secondary"},[_vm._v("Upload Document")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"})]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[(_vm.isUpdated)?_c('vue2Dropzone',{ref:"myVueDropzone",staticClass:"w-full border-dotted",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-success":_vm.fileUploaded,"vdropzone-error":_vm.fileUploadFailed,"vdropzone-sending":_vm.sendingEvent,"vdropzone-files-added":_vm.fileAdded}}):_vm._e()],1)]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"})]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('vs-button',{on:{"click":_vm.submitUser}},[_vm._v("Submit")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }