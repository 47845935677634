<template>
  <gmap-autocomplete class="w-full formfield" @place_changed="setPlace">
  </gmap-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      markers: [],
      places: [],
      currentPlace: null,
      addressData: {},
      selectedAddress: {},
    };
  },

  methods: {
    setPlace(place) {
      this.currentPlace = place;
      const lat = this.currentPlace.geometry.location.lat();
      const lng = this.currentPlace.geometry.location.lng();
      const PlaceId = this.currentPlace.place_id;

      let componentForm = {
        subpremise: "short_name",
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
      };

      this.addressData = {};

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];

          this.addressData = {
            ...this.addressData,
            [addressType]: val,
          };
        } else {
          this.addressData = {
            ...this.addressData,
            [addressType]: "",
          };
        }
        if (place.address_components.length === i + 1) {
          if (this.addressData.subpremise) {
            this.selectedAddress = {
              ...this.selectedAddress,
              addressLine1: this.addressData.subpremise,
              addressLine2:
                this.addressData.street_number + " " + this.addressData.route,
              suburb: this.addressData.locality,
              state: this.addressData.administrative_area_level_1,
              postcode: this.addressData.postal_code,
              latitude: lat,
              longitude: lng,
              addressPlaceId: PlaceId,
            };
            return;
          }
          this.selectedAddress = {
            ...this.selectedAddress,
            addressLine1: this.addressData.street_number,
            addressLine2: this.addressData.route,
            suburb: this.addressData.locality,
            state: this.addressData.administrative_area_level_1,
            postcode: this.addressData.postal_code,
            latitude: lat,
            longitude: lng,
            addressPlaceId: PlaceId,
          };
        }
        this.$emit("parentToChild", this.selectedAddress);
      }
    },
  },
};
</script>
<style scoped>
.formfield {
  padding: 11px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
